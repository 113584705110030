/**
 * @author 贝才[beica1@outook.com]
 * @date 2020/10/28
 * @description
 *   Schema.ts of FastTrade
 */
import * as R from 'ramda'
import { shallowReactive } from 'vue'
import { PropType } from '@vue/runtime-core'

const patterns = {
  // eslint-disable-next-line no-control-regex
  email: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
  cc: /^(\+?\d{1,3}|\d{1,4})$/,
  nothing: /$^/,
  /**
   * 不能_开头的包括字母_数字中文日文的1以上的字符串
   */
  noSpecial: /^[^!@#$%^&*()+|~`\\=\-:;,/<>?]*$/,
}

type Patterns = keyof typeof patterns

type Pattern = RegExp | Patterns

type DefaultFactory<T> = ((props: Data) => T) | null | undefined

interface Prop<T = string, D = T> {
  default?: D | DefaultFactory<D> | null | undefined | Data;
  type?: PropType<T> | true | null;
  required?: boolean;
  pattern?: RegExp | Patterns;

  validator? (value: unknown, form: Data): boolean;

  message?: Partial<{
    required: string;
    invalid: string;
  }>;
}

export type ObjectPropsOptions<P = Data> = {
  [K in keyof P]: Prop<P[K]>;
}

const pickValue = R.mapObjIndexed(R.propOr(null, 'default'))

const VALID = {
  valid: true,
  error: '',
}

export type State = {
  valid: boolean;
  error: string;
}

export type SchemaStates = Record<string, State>

const testPattern = (key: string, value: unknown, pattern: Pattern) => {
  let reg = pattern
  if (typeof pattern === 'string') {
    reg = patterns[pattern] || patterns.nothing
  }
  const valid = (reg as RegExp).test(value as string)
  return {
    valid,
    error: valid ? '' : `${key} is invalid`,
  }
}

export default class Schema {
  private readonly fields: Array<string>
  private readonly schema
  private state: SchemaStates = {}
  readonly values: Data

  constructor (schema: ObjectPropsOptions) {
    this.schema = schema
    this.fields = R.keys(schema)
    this.values = shallowReactive(pickValue(schema))
  }

  validateValue (model: Prop, value: unknown, key: string): State {
    // optional
    if (!model) {
      return VALID
    }

    // test required
    if ((model.required !== false) && !value) {
      return {
        valid: false,
        error: `${key} is required`,
      }
    }

    // test validator
    if (model.validator) {
      const valid = model.validator(value, this.values)
      return {
        valid,
        error: valid ? '' : `${key} is invalid`,
      }
    }

    // test pattern
    if (model.pattern) return testPattern(key, value, model.pattern)

    return VALID
  }

  validateField (key: string) {
    const model = this.schema[key] as Prop
    const value = this.values[key]
    return {
      value,
      ...this.validateValue(model, value, key),
    }
  }

  /**
   * @todo validate only changed or input
   */
  validate (key?: string): SchemaStates {
    // log('validate of', key ?? 'all')
    if (key) {
      const filedState = this.validateField(key)
      this.state = R.set(R.lensProp(key), filedState, this.state)
      return this.state
    }

    this.state = R.reduce(
      R.useWith(R.mergeRight, [
        R.identity,
        R.converge(R.objOf, [R.identity, this.validateField.bind(this)]),
      ]),
      {},
      this.fields,
    )

    return this.state
  }
}
