
import { defineComponent } from 'vue'
import FormItem from '../FormItem.vue'

export default defineComponent({
  name: 'FormInput',
  components: { FormItem },
  props: {
    type: {
      type: String,
      require: false,
    },
  },
})
