
import Button from '@/components/Button.vue'
import FBLoginButton from '@/features/fbLogin/FBLoginButton.vue'
import ThePasswordInput from '@/pages/login/components/ThePasswordInput.vue'
import { emailRegisterSchema, useRegister } from '@/pages/register/register'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Form from 'common/form/Form.vue'
import FormInput from 'common/form/inputs/FormInput.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'RegisterWithEmail',
  components: { FBLoginButton, Button, ThePasswordInput, FormInput, Form, PageWithHeader },
  props: {
    isIslamic: String,
    countryCode: String,
  },
  setup (props) {
    const form = shallowRef()
    const [register, progress] = useRegister(true)
    const islamicCheck = shallowRef(false)

    return {
      form,
      emailRegisterSchema,
      islamicCheck,
      submit () {
        if (form.value) {
          form.value.values.countryCode = props.countryCode
          if (props.isIslamic) {
            form.value.values.islamFlag = Number(islamicCheck.value)
          }
        }
        (form.value as HTMLFormElement).submit()
      },
      register,
      progress,
    }
  },
})
