import { withDeviceIdentities } from '@/modules/app/app'
import { localGet, localSet } from 'essential/store/localStore'
import { keymap } from '@/config'
import { fbLogin, bindFB, User } from '@/features/fbLogin/fbLogin.api'
import { YesOrNo } from '@/types'
import { shallowRef, computed, Ref } from 'vue'
import { useRouter } from 'vue-router'
import { login } from '@/modules/user/user'
import state from '@/state'

type FBParams = {
  fbId: string;
  accessToken: string;
}

type FBAuthResp = {
  status: 'connected',
  authResponse: {
    accessToken: string;
    expiresIn: string;
    reauthorize_required_in: string;
    signedRequest: string;
    userID: string;
  }
} | {
  status: 'not_authorized' | 'unknown';
  authResponse: null;
}

const $_fbLogin = () => {
  return new Promise((resolve, reject) => {
    (window as any).FB.getLoginStatus((resp: FBAuthResp) => {
      if (resp.status === 'connected') {
        resolve({
          ...resp.authResponse,
          fbId: resp.authResponse.userID,
        })
      } else {
        (window as any).FB.login((resp: FBAuthResp) => {
          if (resp.status === 'connected') {
            resolve({
              ...resp.authResponse,
              fbId: resp.authResponse.userID,
            })
          } else {
            reject('FB Auth failed')
          }
        })
      }
    })
  })
}

/**
 * fb登录
 */
export const useFbLogin = (islamFlag: Ref<boolean>) => {
  const router = useRouter()
  const countryCode = computed(() => state.countryCode)

  return async () => {
    const fbAuthResult = await $_fbLogin() as FBParams

    router.back()

    const resp: User = await fbLogin(
      {
        countryCode: countryCode.value,
        islamFlag: Number(islamFlag.value) || 0,
        ...fbAuthResult,
      },
    )

    if (resp.mobileBindStatus !== YesOrNo.YES) {
      router.push('/bindMobile')
    }

    login(resp)

    return resp
  }
}

export const useBindFb = () => {
  const _hasBind = Number(localGet(keymap.user.faceBookBindStatus)) === YesOrNo.YES
  const hasBind = shallowRef(_hasBind)
  const fbId = shallowRef(localGet(keymap.user.fbId))

  const bind = async () => {
    const fbAuthResult = await $_fbLogin() as FBParams
    const bindResult = await bindFB(fbAuthResult as Data)
    localSet(keymap.user.faceBookBindStatus, String(bindResult.faceBookBindStatus))
    hasBind.value = bindResult.faceBookBindStatus === YesOrNo.YES
    localSet(keymap.user.fbId, bindResult.fbId)
    fbId.value = bindResult.fbId
  }

  return {
    hasBind,
    bind,
    fbId,
  }
}
