/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/3/1
 * @description
 *   register.api.ts of WeTrade
 */
import request from '@/common/request/request'
import { User } from '@/modules/user/user.api'

export const requestRegister = request<User>('/api/user/register')

export const requestCode = (countryCode: string, mobile: string) => request('/api/sms/send')({
  type: 1,
  countryCode,
  mobile,
})

export const registerWithEmail = request<User>('/api/user/email/register')
