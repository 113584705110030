import { renderSlot as _renderSlot, toHandlerKey as _toHandlerKey, mergeProps as _mergeProps, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormItem = _resolveComponent("FormItem")

  return (_openBlock(), _createBlock(_component_FormItem, null, {
    default: _withCtx(({ update, mode, attrs, value }) => [
      _renderSlot(_ctx.$slots, "prefix", { value: value }),
      _createVNode("div", _hoisted_1, [
        _createVNode("input", _mergeProps(attrs, {
          type: _ctx.type,
          value: value,
          autocomplete: "off",
          style: {"width":"100%"},
          class: "input",
          [_toHandlerKey(mode)]: ($event: any) => (update($event.target.value))
        }), null, 16, ["type", "value"])
      ]),
      _renderSlot(_ctx.$slots, "addon", {
        value: value,
        update: update
      })
    ]),
    _: 3
  }))
}