
import Button from '@/components/Button.vue'
import useRequest from '@/hooks/useRequest'
import { computed, defineComponent } from 'vue'
import { useFbLogin } from '@/features/fbLogin/fbLogin.fns'

export default defineComponent({
  name: 'FBLoginButton',
  components: { Button },
  props: {
    primary: Boolean,
    islamFlag: Boolean,
  },
  setup (props) {
    const flag = computed(() => props.islamFlag)
    const [login, progress] = useRequest(useFbLogin(flag))

    return {
      login,
      progress,
    }
  },
})
