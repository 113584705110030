import { renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("form", {
    class: [_ctx.$attrs.class, "form"],
    style: _ctx.$attrs.style,
    onUpdate: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.update && _ctx.update(...args))),
    onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
  }, [
    _renderSlot(_ctx.$slots, "default", {
      values: _ctx.values,
      submit: _ctx.submit
    })
  ], 38))
}