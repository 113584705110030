
import Icon from '@/components/Icon.vue'
import Password from 'common/form/inputs/Password.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ThePasswordInput',
  props: {
    iconClass: {
      type: String,
      default: 'c-primary',
    },
  },
  components: { Icon, Password },
})
