import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")

  return (_openBlock(), _createBlock(_component_Input, { type: _ctx.type }, {
    addon: _withCtx(() => [
      _renderSlot(_ctx.$slots, "addon", {
        toggle: _ctx.toggle,
        visible: _ctx.type === 'text'
      })
    ]),
    _: 1
  }, 8, ["type"]))
}