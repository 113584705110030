import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")
  const _component_Password = _resolveComponent("Password")
  const _component_t = _resolveComponent("t")

  return (_openBlock(), _createBlock(_component_t, {
    path: "signinup_17",
    as: "div",
    custom: ""
  }, {
    default: _withCtx(({td}) => [
      _createVNode(_component_Password, _mergeProps({
        name: "password",
        placeholder: td || 'Password'
      }, _ctx.$attrs), {
        addon: _withCtx(({ toggle, visible }) => [
          _createVNode(_component_Icon, {
            class: _ctx.iconClass,
            name: visible ? 'eye-open' : 'eye-closed',
            style: {"margin":"0 8px"},
            onClick: toggle
          }, null, 8, ["class", "name", "onClick"])
        ]),
        _: 2
      }, 1040, ["placeholder"])
    ]),
    _: 1
  }))
}