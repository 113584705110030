
import Input from 'common/form/inputs/FormInput.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'Password',
  components: { Input },
  setup () {
    const type = shallowRef('password')

    const toggle = () => {
      const next = Number(type.value !== 'password')
      type.value = ['text', 'password'][next]
    }

    return {
      type,
      toggle,
    }
  },
})
