import { renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    ref: "root",
    class: ["form-item", [_ctx.invalid && 'invalid']]
  }, [
    _renderSlot(_ctx.$slots, "default", {
      attrs: _ctx.attrs,
      meta: _ctx.meta,
      mode: _ctx.mode,
      update: _ctx.update,
      value: _ctx.values[_ctx.name],
      values: _ctx.values
    })
  ], 2))
}