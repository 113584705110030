
import * as R from 'ramda'
import { inject, computed, ref, defineComponent, Ref } from 'vue'
import { SchemaStates } from './Schema'

export default defineComponent(
  {
    name: 'FormItem',
    props: {
      name: {
        type: String,
        required: true,
      },
      defaultValue: String,
    },
    setup (props, ctx) {
      const root = ref(null)
      const state = inject<Ref<SchemaStates>>('state')
      const values = inject<Record<string, unknown>>('values')

      const meta = computed(() => state?.value[props.name])
      const invalid = computed(() => {
        return meta.value?.valid === false
      })

      const update = (v: string) => {
        if (values) {
          values[props.name] = v
          ;(root.value as Element | null)?.dispatchEvent(new CustomEvent('update', {
            bubbles: true,
            detail: {
              key: props.name,
            },
          }))
        }
      }

      if (props.defaultValue) update(props.defaultValue)

      return {
        attrs: R.omit(['class', 'style'], ctx.attrs),
        mode: inject('mode'),
        values,
        state,
        meta,
        invalid,
        root,
        update,
      }
    },
  },
)
