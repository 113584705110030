/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/3/1
 * @description
 *   login.api.ts of WeTrade
 */
import request from '@/common/request/request'
import { User } from '@/modules/user/user.api'

export const requestLogin = request<User>('/api/user/login')

export const loginWithEmail = request<User>('/api/user/email/login')
